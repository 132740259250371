import { TraceEvent } from "@uniswap/analytics";
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
} from "@uniswap/analytics-events";
import { useWeb3React } from "@web3-react/core";
import { useToggleAccountDrawer } from "components/AccountDrawer";
import CustomSwitch from "components/NavBar/Switch";
import { ButtonPrimary, ButtonText } from "components/Button";
import { AutoColumn } from "components/Column";
import { SupportedChainId } from "constants/chains";
import { usePagination } from "hooks/usePagination";
import { PoolData, fetchUserLiquidity } from "graphql/thegraph/PoolQuery";
import { ElektrikLoader } from "components/Icons/LoadingSpinner";
import { useMemo, useState, useCallback } from "react";
import {
  Search,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
} from "react-feather";
import { useTheme } from "styled-components/macro";
import { ThemedText } from "theme";
import { PoolTableTabs } from "./PoolPageTabs";
import { IconInput, IconInputContainer } from "components/IconInput";
import CreatePoolCard from "./CreatePoolCard";
import {
  ErrorContainer,
  InboxIcon,
  PoolPageWrapper,
  ConnectWalletWrapper,
  HeaderWrapper,
  UserPoolStats,
  StatsContainer,
  RewardsBottomContainer,
  PoolsSection,
  PoolTableHeader,
  TabsWrapper,
  PoolFilterWrapper,
  PoolTableContainer,
  ClickableText,
  TableHead,
  TableBody,
  PaginationWrapper,
  NumbersContainer,
  PageNumber,
  HeaderTitle,
  HeaderSubTitle,
  HeaderTitleSecondary,
  HeaderSubTitleSecondary,
  TableLoaderContainer,
  ResponsiveGridPoolHead,
  PoolTableWrapperPool,
} from "./styleds";
import { useGetAllPositionsPendingRewards } from "hooks/staking/useGetAlPositionsPendingRewards";
import { POOL_SORT_FIELD } from "constants/misc";
import { getChainAddresses } from "@eltk/staking-sdk";
import { useGetUsdOraclePrice } from "hooks/oracle/useGetUsdOraclePrice";
import { usePoolData } from "hooks/pool/usePoolData";
import PoolRow from "./PoolRow";
import useGetTotalFeeUsd from "hooks/pool/useGetTotalFeeUsd";
import useGetTotalValueLocked from "hooks/pool/useGetTotalValueLocked";
import { MouseoverTooltip } from "components/Tooltip";
import { WrongNetworkCard } from "./WrongNetworkCard";

import { EmptyComponent } from "components/EmptyComponent";
import { formatter, formatterWithoutDollar } from "utils/locking";
import { LoadingBubble } from "components/Tokens/loading";
import { useGetPoolIds } from "hooks/voting/useGetAllPools";

export default function Pool() {
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [activeTab, setActiveTab] = useState<any>(1);
  const [myLiquidity, setMyLiquidity] = useState<boolean>(false);
  const [sortField, setSortField] = useState<any>(POOL_SORT_FIELD.volumeUSD);
  const [sortDirection, setSortDirection] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [myPools, setMyPools] = useState<any>(null);

  const { account, chainId, provider } = useWeb3React();
  const toggleWalletDrawer = useToggleAccountDrawer();
  const theme = useTheme();

  const {
     data:poolsData,
    error: poolsError,
    loading: poolsLoading,
  } = useGetPoolIds(chainId);
  
  const {
    data: poolDataQuery,
    loading: poolDataLoading,
    error: poolDataError,
  } = usePoolData(chainId);



  const {
    totalBalance,
    loading: unclaimedRewardsLoading,
    balances,
    tokenIds,
  } = useGetAllPositionsPendingRewards(account);

  const { oracleUsdPrice, loading: oracleUsdPriceLoading } =
    useGetUsdOraclePrice(
      totalBalance / 10 ** 18,
      getChainAddresses(chainId ?? 1891)?.ElektrikUtility,
      1
    );




  const { totalFeesUSD, loading: totalFeesUSDLoading } = useGetTotalFeeUsd(
    poolDataQuery?.pools.filter((pool: any) =>
      poolsData.includes(pool?.id?.toLowerCase())
    )
  );


  const { totalValueLocked, loading: totalValueLockedLoading } =
    useGetTotalValueLocked(
      poolDataQuery?.pools.filter((pool: any) =>
        poolsData.includes(pool?.id?.toLowerCase())
      )
    );

  const {
    nextPage,
    prevPage,
    goToPage,
    currentItems,
    currentPage,
    totalPages,
  } = usePagination({
    data: poolDataQuery?.pools,
    itemsPerPage: 14,
  });

  const sortedPools = useMemo(() => {
    const dataSource = myLiquidity
      ? myPools
      : poolDataQuery?.pools
    return dataSource
      ? [...dataSource]
          .sort((a, b) => {
            if (a && b) {
              // @todo: find a better way to sort if other sort options are added
              return Number(a[sortField as keyof PoolData]) >
                Number(b[sortField as keyof PoolData])
                ? (sortDirection ? -1 : 1) * 1
                : (sortDirection ? -1 : 1) * -1;
            } else {
              return -1;
            }
          })
          .slice(currentItems?.start, currentItems?.end)
      : [];
  }, [myLiquidity, myPools, poolDataQuery?.pools, currentItems?.start, currentItems?.end, sortField, sortDirection]);

  const handleSort = useCallback(
    (newField: string) => {
      setSortField(newField);
      setSortDirection(sortField !== newField ? true : !sortDirection);
    },
    [sortDirection, sortField]
  );

  const arrow = useCallback(
    (field: string) => {
   
      return sortField === field ? (
        !sortDirection ? (
          <ChevronUp size={15} style={{ marginLeft: "0.6rem" }} />
        ) : (
          <ChevronDown size={15} style={{ marginLeft: "0.6rem" }} />
        )
      ) : (
        <ChevronDown size={15} style={{ marginLeft: "0.6rem" }} />
      );
    },
    [sortDirection, sortField]
  );

  const fetchLiquidityData = async (pool: PoolData, account: any) => {
    try {
      const response = await fetchUserLiquidity(pool, account!);
      return response;
    } catch (error) {
      console.error("Error Fetching Liquidity Data For Pool", error);
    }
  };

  const showConnectAWallet = Boolean(!account);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filteredPools = sortedPools.filter((pool) => {
    return (
      searchTerm === "" ||
      pool.token1.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pool.token0.symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const handleMyLiquiditySwitch = async () => {
    setLoading(true);
    try {
      const liquidityDataPromises = poolDataQuery?.pools
        .filter((pool: any) => poolsData.includes(pool?.id?.toLowerCase()))
        ?.map((pool: any) => fetchLiquidityData(pool, account));

      poolDataQuery?.pools.filter((pool: any) =>
        poolsData.includes(pool?.id?.toLowerCase())
      );
      const filteredPromises = liquidityDataPromises?.filter(
        (promise: any) => promise !== undefined
      );
      if (filteredPromises?.length === 0) {
        return;
      }
      const liquidityData = await Promise.all(filteredPromises!);
      setMyPools(liquidityData);
      setLoading(false);
      setMyLiquidity(!myLiquidity);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching liquidity data:", error);
    }
  };

  if (chainId !== SupportedChainId.LIGHTLINK_PEGASUS_TESTNET && chainId !== SupportedChainId.LIGHTLINK_PHOENIX_MAINNET) {
    return (
      <WrongNetworkCard
        content="Please switch your chain to one of the Lightlink supported chain"
      />
    );
  }

  return (
    <PoolPageWrapper>
      <AutoColumn gap="lg" justify="center">
        <AutoColumn gap="lg" style={{ width: "100%" }}>
          <HeaderWrapper>
            <HeaderTitle>Provide Liquidity & Earn $ELTK</HeaderTitle>
            <HeaderSubTitle>
              Join others maximising returns by staking their LP Positions
            </HeaderSubTitle>

            {showConnectAWallet && (
              <ConnectWalletWrapper>
                <ErrorContainer>
                  <ThemedText.DeprecatedBody
                    color={theme.textTertiary}
                    textAlign="center"
                  >
                    <InboxIcon strokeWidth={2} style={{ marginTop: "2em" }} />
                  </ThemedText.DeprecatedBody>
                  <TraceEvent
                    events={[BrowserEvent.onClick]}
                    name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                    properties={{ received_swap_quote: false }}
                    element={InterfaceElementName.CONNECT_WALLET_BUTTON}
                  >
                    <ButtonPrimary
                      style={{
                        marginBottom: "2em",
                        padding: "8px 16px",
                        background: `linear-gradient(146deg, #a1eeff 0%, #029af0 100%)`,
                        color: "#000",
                        borderRadius: "10px",
                      }}
                      onClick={toggleWalletDrawer}
                    >
                      <div>Connect wallet</div>
                    </ButtonPrimary>
                  </TraceEvent>
                </ErrorContainer>
              </ConnectWalletWrapper>
            )}
            {!showConnectAWallet && (
              <UserPoolStats>
                <StatsContainer style={{ minHeight: 97 }}>
                  <MouseoverTooltip
                    placement="left"
                    text=" Total fees earned on pools"
                  >
                    <ThemedText.DeprecatedWhite
                      fontWeight={600}
                      fontSize="16px"
                    >
                      <div>Total Fees:</div>
                    </ThemedText.DeprecatedWhite>
                  </MouseoverTooltip>
                  <RewardsBottomContainer>
                    {totalFeesUSDLoading ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <ThemedText.TextGradient fontWeight={600} fontSize="30px">
                        <div>{formatter.format(totalFeesUSD)}</div>
                      </ThemedText.TextGradient>
                    )}
                  </RewardsBottomContainer>
                </StatsContainer>

                <StatsContainer style={{ minHeight: 97 }}>
                  <MouseoverTooltip
                    placement="left"
                    text="The total value of tokens locked in the liquidity pool"
                  >
                    <ThemedText.DeprecatedWhite
                      fontWeight={600}
                      fontSize="16px"
                    >
                      <div>TVL:</div>
                    </ThemedText.DeprecatedWhite>
                  </MouseoverTooltip>
                  <RewardsBottomContainer>
                    {totalValueLockedLoading ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <ThemedText.TextGradient fontWeight={600} fontSize="30px">
                        <div>{formatter.format(totalValueLocked)}</div>
                      </ThemedText.TextGradient>
                    )}
                  </RewardsBottomContainer>
                </StatsContainer>
                <StatsContainer style={{ minHeight: 97 }}>
                  <MouseoverTooltip
                    placement="left"
                    text="Total rewards on your staked Lp Positions"
                  >
                    <ThemedText.DeprecatedWhite
                      fontWeight={600}
                      fontSize="16px"
                    >
                      <div>Total Rewards:</div>
                    </ThemedText.DeprecatedWhite>
                  </MouseoverTooltip>
                  <RewardsBottomContainer>
                    {unclaimedRewardsLoading ? (
                      <LoadingBubble height="25px" width="80px" delay="300ms" />
                    ) : (
                      <div style={{ display: "flex", alignItems: "baseline" }}>
                        <ThemedText.TextGradient
                          fontWeight={600}
                          fontSize="30px"
                        >
                          <div>{formatter.format(oracleUsdPrice) || 0}</div>
                        </ThemedText.TextGradient>
                        <span
                          style={{
                            color: "#fff",
                            opacity: "0.5",
                            fontSize: "15px",
                            marginLeft: "5px",
                          }}
                        >
                          {formatterWithoutDollar.format(
                            totalBalance / 10 ** 18
                          )}{" "}
                          ELTK
                        </span>
                      </div>
                    )}
                  </RewardsBottomContainer>
                </StatsContainer>
              </UserPoolStats>
            )}
          </HeaderWrapper>
          <PoolsSection>
            <HeaderTitleSecondary>Explore Pools</HeaderTitleSecondary>
            <HeaderSubTitleSecondary>
              This optimal fund utilisation does not just boost profitability,
              it also works to reduce impermanent loss.
            </HeaderSubTitleSecondary>

            <PoolTableWrapperPool >
              <PoolTableHeader>
                <TabsWrapper>
                  <PoolTableTabs
                    active={activeTab}
                    changeTab={(tab: number) => setActiveTab(tab)}
                  />
                </TabsWrapper>
                {activeTab === 1 && (
                  <PoolFilterWrapper>
                    {!showConnectAWallet && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CustomSwitch
                            size="lg"
                            isChecked={myLiquidity}
                            toggleSwitch={handleMyLiquiditySwitch}
                          />
                          <ThemedText.DeprecatedWhite
                            fontWeight={600}
                            fontSize="12px"
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <div>My Liquidity Only</div>
                          </ThemedText.DeprecatedWhite>
                        </div>
                      </>
                    )}

                    <IconInputContainer>
                      <IconInput
                        data-testid="search-input"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleInputChange}
                      />
                      <Search size={16} />
                    </IconInputContainer>
                  </PoolFilterWrapper>
                )}
              </PoolTableHeader>

              {activeTab === 2 && <CreatePoolCard />}

              {activeTab === 1 && (
                <PoolTableContainer>
                  <TableHead>
                    <ResponsiveGridPoolHead>
                      <ClickableText onClick={() => null}>
                        Pool Name / Number
                      </ClickableText>

                      {!myLiquidity && <ClickableText>Pool APR</ClickableText>}

                      {myLiquidity && (
                        <ClickableText onClick={() => null}>
                          My Liquidity
                          <ChevronDown
                            size={15}
                            style={{ marginLeft: "0.6rem" }}
                          />
                        </ClickableText>
                      )}
                      <ClickableText
                        onClick={() =>
                          handleSort(POOL_SORT_FIELD.totalValueLockedUSD)
                        }
                      >
                        TVL
                        {arrow(POOL_SORT_FIELD.totalValueLockedUSD)}
                      </ClickableText>
                      <ClickableText
                        onClick={() => handleSort(POOL_SORT_FIELD.volumeUSD)}
                      >
                        Volume
                        {arrow(POOL_SORT_FIELD.volumeUSD)}
                      </ClickableText>

                      <ClickableText>Pool Balance</ClickableText>

                      <ClickableText onClick={() => null}>
                        Fees (24h)
                      </ClickableText>

                      <ClickableText onClick={() => null}>
                        My Balance
                      </ClickableText>

                      <ClickableText onClick={() => null}>
                        My Staked Balance
                      </ClickableText>
                    </ResponsiveGridPoolHead>
                  </TableHead>
                  <TableBody>
                    {poolDataLoading && (
                      <TableLoaderContainer>
                        <ElektrikLoader fill={true} />
                      </TableLoaderContainer>
                    )}
                    {filteredPools && filteredPools.length
                      ? filteredPools?.map((filterPools: any, i) => {
                          return (
                            <PoolRow
                              item={filterPools}
                              chainId={chainId}
                              myLiquidity={myLiquidity}
                            />
                          );
                        })
                      : !poolDataLoading && (
                          <EmptyComponent
                            content="Data not available"
                            style={{ height: "50vh" }}
                          />
                        )}
                  </TableBody>
                  {totalPages > 1 && (
                    <PaginationWrapper>
                      <ButtonText onClick={() => prevPage()}>
                        <ChevronLeft />
                      </ButtonText>
                      <NumbersContainer>
                        {[...Array(totalPages).keys()]
                          .filter((page) => {
                            const maxPagesToShow = 5;
                            const halfMaxPagesToShow = Math.floor(
                              maxPagesToShow / 2
                            );
                            const safeCurrentPage = currentPage || 1;
                            let startPage = Math.max(
                              safeCurrentPage - halfMaxPagesToShow,
                              1
                            );
                            const endPage = Math.min(
                              startPage + maxPagesToShow - 1,
                              totalPages
                            );

                            if (
                              safeCurrentPage >
                              totalPages - halfMaxPagesToShow
                            ) {
                              startPage = Math.max(
                                totalPages - maxPagesToShow + 1,
                                1
                              );
                            }

                            return page + 1 >= startPage && page + 1 <= endPage;
                          })
                          .map((item) => {
                            return (
                              <PageNumber
                                onClick={() => goToPage(item + 1)}
                                key={item}
                                selected={currentPage === item + 1}
                              >
                                <span> {item + 1}</span>
                              </PageNumber>
                            );
                          })}
                      </NumbersContainer>

                      <ButtonText onClick={() => nextPage()}>
                        <ChevronRight />
                      </ButtonText>
                    </PaginationWrapper>
                  )}
                </PoolTableContainer>
              )}
            </PoolTableWrapperPool>
          </PoolsSection>
        </AutoColumn>
      </AutoColumn>
    </PoolPageWrapper>
  );
}
